import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Worcester, <span>South Africa</span>
                </h2>
                <p>168 Grey St, Parkersdam, Worcester, South Africa</p>
                <Link to="/">
                    <span>Email:</span> arkua@tiscali.co.za
                </Link>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>Call directly:</h6>
                    <p>(023) 342 6923</p>
                </div>
                <div className="info-tem">
                    <h6>Offices:</h6>
                    <p>Medigrey Clinic</p>
                </div>
                <div className="info-tem mb-0">
                    <h6>Work Hours:</h6>
                    <p>Monday - Thursday: 9 AM - 4 PM</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
