import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";

const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Logo
                                    classOption="footer-logo"
                                    image={`${process.env.PUBLIC_URL}/img/logo-dark.png`}
                                />
                                <p className="mb-0">
                                    Delivering exceptional quality, affordable
                                    family healthcare
                                </p>
                                <ul className="widget-contact-info">
                                    <li className="info-address">
                                        <i className="icofont-location-pin"></i>
                                        168 Grey St, Worcester, 6849, South
                                        Africa
                                    </li>
                                    <li className="info-mail">
                                        <i className="icofont-email"></i>
                                        <a href="mailto://hello@yourdomain.com">
                                            arkua@tiscali.co.za
                                        </a>
                                    </li>
                                    <li className="info-phone">
                                        <i className="icofont-ui-call"></i>
                                        <a href="tel://0233426923">
                                            (023) 342 6923
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3 d-lg-none d-xl-block">
                        <div className="widget-item d-inline-block">
                            <h4 className="widget-title line-bottom">
                                Services
                            </h4>
                            <div className="widget-tags">
                                <ul>
                                    <li>
                                        <Link to="/service-details/1">
                                            Check-Ups
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/2">
                                            Family Care
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/3">
                                            Diagnosis
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/3">
                                            Treatment
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/4">
                                            Health Information
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/5">
                                            Nutrition Advice
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/6">
                                            Prescriptions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/7">
                                            Acute Management
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/8">
                                            Chronic Mangement
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/9">
                                            Screening
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details/10">
                                            Referrals
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom">
                                Site Navigation
                            </h4>
                            <nav className="widget-posts">
                                <ul className="posts-item">
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-details">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Services Details
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Contact Us
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-4 offset-lg-0 col-xl-3">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom">
                                Newsletter
                            </h4>
                            <div className="widget-newsletter">
                                <p>
                                    Sign up for our mailing list to get latest
                                    updates and offers.
                                </p>
                                <Newsletter mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
                            </div>
                            <div className="widget-social-icons">
                                <SocialIcon
                                    path="https://www.facebook.com/"
                                    icon="icofont-facebook"
                                />
                                <SocialIcon
                                    path="https://www.instagram.com/"
                                    icon="icofont-instagram"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <div className="widget-copyright">
                                <p>
                                    &copy; 2021{" "}
                                    <span className="text-uppercase">
                                        medigrey
                                    </span>
                                    . Designed and Built by{" "}
                                    <a
                                        target="_blank"
                                        href="https://www.nextleveltechnology.org"
                                        rel="noreferrer"
                                    >
                                        NEXTLEVEL Technology
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
