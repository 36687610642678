import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="Dr AM Ebrahim"
            className="contact-map"
            src="https://maps.google.com/maps?width=100%25&amp;hl=en&amp;q=168%20grey%20street%20worcester+(Dr%20AM%20Ebrahim)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
