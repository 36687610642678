import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";
import MedicalFeature from "../../../components/medical-feature";

const MedicalFeatureContainer = () => {
    return (
        <>
            <section className="feature-section bg-white feature-section-padding1 mb-m100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                subTitle="Why Choose Us"
                                title="<span>The Best</span> For Your Health"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-xl-8">
                            <div
                                className="row icon-box-style"
                                data-aos="fade-up"
                                data-aos-duration="1100"
                            >
                                {HomeData[3].medicalFeature &&
                                    HomeData[3].medicalFeature
                                        .slice(0, 6)
                                        .map((single, key) => {
                                            return (
                                                <div
                                                    key={key}
                                                    className="col-md-6"
                                                >
                                                    <MedicalFeature
                                                        key={key}
                                                        data={single}
                                                    />
                                                </div>
                                            );
                                        })}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="thumb"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                >
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "./img/photos/features-03.jpg"
                        }
                        alt="hope"
                    />
                </div>
            </section>
            <section className="feature-section bg-white feature-section-padding2 mt-m100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                subTitle="Why Choose Us"
                                title="<span>Holistic and Comprehensive</span> Healthcare"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-xl-8">
                            <div
                                className="row icon-box-style"
                                data-aos="fade-up"
                                data-aos-duration="1100"
                            >
                                {HomeData[3].medicalFeature &&
                                    HomeData[3].medicalFeature
                                        .slice(6, 10)
                                        .map((single, key) => {
                                            return (
                                                <div
                                                    key={key}
                                                    className="col-md-6"
                                                >
                                                    <MedicalFeature
                                                        key={key}
                                                        data={single}
                                                    />
                                                </div>
                                            );
                                        })}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="thumb"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                >
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "./img/photos/features-01.jpg"
                        }
                        alt="hope"
                    />
                </div>
            </section>
        </>
    );
};

export default MedicalFeatureContainer;
